import React from 'react'
import { NavLink } from 'react-router-dom'

export default function TableRow (props) {
  return (
    <tr
      className={`${!props.header && !props.open.includes(props.doc.MndtId) ? ` d-none` : ``} ${props.header ? ` header` : ``}`}
      onClick={e => props.setOpen(props.open.includes(props.doc.MndtId) ? props.open.filter(mnd => mnd !== props.doc.MndtId) : [...props.open, props.doc.MndtId])}>
      <td className={props.header ? `table-active` : ``}>{props.doc.BookgDt}</td>
      <td className={props.header ? `table-active` : ``}>
        {props.profile && props.profile.doc ? (
          <NavLink
            to={`/hartzbreaker/${props.profile.id}`}
          >
            {props.doc.RmtdNm}
          </NavLink>
        ) : props.doc.RmtdNm}
      </td>
      <td className={props.header ? `table-active` : ``}>{props.doc.MndtId}</td>
      <td className={props.header ? `table-active` : ``}>{props.doc.RmtdAcctIBAN}</td>
      <td className={props.header ? `table-active` : ``}>{props.doc.RmtInf}</td>
      <td className={props.header ? `table-active` : ``}>{props.doc.Amt}</td>
      <td
        className={props.header ? `table-active` : ``}>{props.profile && props.profile.doc && props.profile.doc._attachments && (
        <NavLink
          to={`/hartzbreaker/${props.profile.id}`}
        >
          PDF
        </NavLink>
      )}</td>
    </tr>
  )
}
