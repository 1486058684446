import React, { useContext, useEffect, useState } from 'react'
import './App.css'
import { NavLink } from 'react-router-dom'
import withAuth from './withAuth'
import WithLoading from './WithLoading'
import Pagination from './Pagination'
import AuthService from './AuthService.jsx'
import { downloadHB } from './helper.js'
import { AppContext } from './AppProvider.jsx'

const Auth = AuthService.getInstance()
const Hartzbreakers = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const [filter, setFilter] = useState('')
  const [loading, setloading] = useState(true)
  useEffect(() => {
    if (state.hartzbreaker.length === 0) {
      Auth.fetch(`/hartzbreaker`).then(data => {
        dispatch({
          type: 'FETCH_HARTZBREAKERS',
          data: data.rows.sort((a, b) => a.id.localeCompare(b.id))
        })
        setloading(false)
      })
    } else {
      setloading(false)
    }
  }, [])
  let hartzbreaker = state.hartzbreaker
  hartzbreaker = filter === '' ? hartzbreaker : hartzbreaker.filter(i =>
    i.key.toLowerCase().replace(/\s/g, '').indexOf(filter.toLowerCase().replace(/\s/g, '')) !== -1 ||
    i.value.toLowerCase().replace(/\s/g, '').indexOf(filter.toLowerCase().replace(/\s/g, '')) !== -1
  )
  hartzbreaker = hartzbreaker.slice(state.offset, state.offset + state.limit)
  return (
    <div className="container-fluid">
      <div className={`d-flex align-items-center form-group flex-wrap flex-md-nowrap`}>
        <div className="w-100 mr-3 mb-3 mb-md-0">
          <input
            autoFocus={true}
            className="form-control"
            type="text"
            placeholder="Filter by email or iban"
            onChange={e => setFilter(e.target.value)}
          />
        </div>
        <div className="btn-group" role="group" aria-label="Basic example">
          <button className="btn btn-outline-primary no-wrap" data-type="/generate/hartzbreaker_masked" onClick={downloadHB}>
            HBs masked
          </button>
          <button className="btn btn-outline-primary no-wrap" data-type="/generate/hartzbreaker" onClick={downloadHB}>
            HBs
          </button>
          <button className="btn btn-outline-primary no-wrap" data-type="/generate/newsletter" onClick={downloadHB}>
            NL subscribers
          </button>
        </div>
      </div>
      <WithLoading loading={loading} waitfor={hartzbreaker}>
        <Pagination items={state.hartzbreaker}/>   
        <table className="table table-sm table-striped">
          <thead>
          <tr>
            <th>ID</th>
            <th>IBAN</th>
          </tr>
          </thead>
          <tbody>
          {hartzbreaker.map((row, index) => (
            <tr key={index} className="">
              <td>
                <NavLink
                  to={`/hartzbreaker/${row.id}`}
                  className="nav-link"
                  activeClassName="active"
                >
                  {row.id}
                </NavLink>
              </td>
              <td>{row.key}</td>
            </tr>
          ))}
          </tbody>
        </table>
        <Pagination items={state.hartzbreaker}/>
      </WithLoading>
    </div>
  )
}

export default withAuth(Hartzbreakers)
