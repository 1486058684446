
import AuthService from './AuthService.jsx'
const Auth = AuthService.getInstance()
export const downloadHB = event => {
    return new Promise(function(resolve, reject){
        let amount = ''
        const endpoint = event.target.dataset.type
        if(event.target.dataset.confirm && !window.confirm(event.target.dataset.confirm)){
            return;
        }
        //The first fetch request starts the pdf export and zipping process
        Auth.fetch(endpoint, {}, true).then(data => {
            if (data.statusCode === 500) {
                return Promise.reject(data).catch(e => {
                    throw new Error(e.message)
                })}
            if (data.status === 204) {
                    return Promise.reject(data).catch(e => {
                        throw new Error(data.statusText)
                    })
                }
            if (data.status === 200) {
                amount = data.statusText
                resolve(data)
                return data
            }}).then(data => data.arrayBuffer()).then(buffer => {
                var pom = document.createElement('a')
                var blob = new Blob([buffer], { type: 'text/csv;charset=utf-8;' })
                pom.href = URL.createObjectURL(blob)
                pom.setAttribute(
                    'download',
                    `${new Date().toISOString().slice(0, -14)}_${endpoint.replace(/\//, '')}__amount=${amount}.csv`
                )
                pom.click()
            }).then(() => {
                Auth.fetch('/download-archive', {}, true).then(data => {
                 //The second fetch starts the download
                data.error ? reject(console.error(data)) : data.arrayBuffer().then(async buffer => {
                    var pom = document.createElement('a')
                    var blob = new Blob([buffer], { type: 'application/zip' })
                    pom.href = URL.createObjectURL(blob)
                    pom.setAttribute(
                        'download',
                        `${new Date().toISOString().slice(0, -14)}_${endpoint.replace(/\//, '')}__amount=${amount}.zip`
                    )
                    await pom.click()
                    if (data.status === 200) {
                        resolve()
                    }
                })
            })
            }).catch(err => {
            reject(err)    
        })
    })
}

export const downloadWinners = event => {
    return new Promise(function (resolve, reject) {
        let amount = ''
        const endpoint = event.target.dataset.type
        /*         if (event.target.dataset.confirm && !window.confirm(event.target.dataset.confirm)) {
                    return;
                } */
        //The first fetch request starts the pdf export and zipping process
        Auth.fetch(endpoint, {}, true).then(data => {
            if (data.statusCode === 500) {
                return Promise.reject(data).catch(e => {
                    throw new Error(e.message)
                })
            }
            if (data.status === 204) {
                return Promise.reject(data).catch(e => {
                    throw new Error(data.statusText)
                })
            }
            if (data.status === 200) {
                amount = data.statusText
                resolve(data)
                return data
            }
        }).then(data => data.arrayBuffer()).then(buffer => {
            var pom = document.createElement('a')
            var blob = new Blob([buffer], { type: 'text/csv;charset=utf-8;' })
            pom.href = URL.createObjectURL(blob)
            pom.setAttribute(
                'download',
                `${new Date().toISOString().slice(0, -14)}_${endpoint.replace(/\//, '')}__amount=${amount}.csv`
            )
            pom.click()
        }).catch(err => {
            reject(err)
        })
    })
}
export const downloadUploads = event => {
    return new Promise(function (resolve, reject) {
        let amount = ''
        const endpoint = event.target.dataset.type
        if (event.target.dataset.confirm && !window.confirm(event.target.dataset.confirm)) {
            return;
        }
        //The first fetch request starts the pdf export and zipping process
        Auth.fetch(endpoint, {}, true).then(data => {
            if (data.statusCode === 500) {
                return Promise.reject(data).catch(e => {
                    throw new Error(e.message)
                })
            }
            if (data.status === 204) {
                return Promise.reject(data).catch(e => {
                    throw new Error(data.statusText)
                })
            }
            if (data.status === 200) {
                amount = data.statusText
                resolve(data)
                return data
            }
        }).then(() => {
            Auth.fetch('/download-archive', {}, true).then(data => {
                //The second fetch starts the download
                data.error ? reject(console.error(data)) : data.arrayBuffer().then(async buffer => {
                    var pom = document.createElement('a')
                    var blob = new Blob([buffer], { type: 'application/zip' })
                    pom.href = URL.createObjectURL(blob)
                    pom.setAttribute(
                        'download',
                        `${new Date().toISOString().slice(0, -14)}_${endpoint.replace(/\//, '')}__amount=${amount}.zip`
                    )
                    await pom.click()
                    if (data.status === 200) {
                        resolve()
                    }
                })
            })
        }).catch(err => {
            reject(err)
        })
    })
}