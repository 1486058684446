import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "./AppProvider";
import logo from "./logo.svg";
let timeout;
const Toast = props => {
  const { state, dispatch } = useContext(AppContext);
  useEffect(() => {
    let cleared;
    state.toasts.map((toast, index) => {
      if (!toast.loading && !toast.actions && toast.time < Date.now()) {
        clearTimeout(cleared);
        cleared = setTimeout(() => {
          dispatch({ type: "REMOVE_TOAST", data: toast.time });
        }, 5000);
      }
    });
    document.addEventListener("keydown", e => {
      switch (e.keyCode) {
        // escape key
        case 27:
          dispatch({ type: "CLEAR_TOASTS" });
          break;
        default:
          break;
      }
    });
    return () => {
      document.removeEventListener("keydown", e => {
        // console.log("Eventlistener", e.keyCode);
      });
    };
  }, [state.toasts]);

  return (
    <aside className="toasts">
      {state.toasts.map((toast, index) => (
        <div
          key={index}
          className={`toast d-flex align-items-center alert alert-${toast.type} open`}
          onClick={e => {}}
        >
          <span>{toast.message}</span>
          {toast &&
          toast.actions &&
          toast.actions.map(action => (
            <button key={`button_${index}`} className="btn btn-sm ml-2 btn-outline-white" onClick={(e, ...props)=> {
              dispatch({ type: "REMOVE_TOAST", data: toast.time })
              action.callback(props)
            }}>
              {action.title}
            </button>
          ))}
          {toast.loading && (
            <img src={logo} className="App-logo mr-3" width="50" alt="logo" />
          )}
        </div>
      ))}
    </aside>
  );
};
export default Toast;
