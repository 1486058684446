import React, { useReducer, useEffect, useState, useContext } from 'react'
import { AppContext } from './AppProvider'

const Pagination = ({ items }, ...props) => {
  const { state, dispatch } = useContext(AppContext)
  let pages = []
  for (let i = 0; i < (items.length / state.limit); i++) {
    pages.push(i)
  }
  return pages.length > 1 && (
    <>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${state.offset <= 0 ? `disabled` : ``}`}>
            <a onClick={() => dispatch({ type: 'SETPAGE', data: state.offset - state.limit })}
               className="page-link" href="#">Previous</a>
          </li>
          {pages.map((page, index) => (
            <li key={index} className={`page-item ${(page * state.limit) === state.offset ? ` active` : ``}`}>
              <a onClick={() => dispatch({ type: 'SETPAGE', data: page * state.limit })}
                 className="page-link" href="#">{page}</a>
            </li>
          ))}
          <li className={`page-item ${state.offset + state.limit >= items.length ? `disabled` : ``}`}>
            <a onClick={() => dispatch({ type: 'SETPAGE', data: state.offset + state.limit })}
               className="page-link" href="#">Next</a>
          </li>
        </ul>
      </nav>
    </>
  )
}
export default Pagination
