import React, { useReducer, useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import AuthService from "./AuthService.jsx";
const Auth = AuthService.getInstance();

export default function withAuth(AuthComponent) {
  const AuthWrapped = props => {
    const [user, setUser] = useState(undefined);

    useEffect(() => {
      if (!Auth.loggedIn()) {
        props.history.replace("/signin");
      } else {
        try {
          const profile = Auth.getProfile();
          setUser(profile);
        } catch (err) {
          console.log("err", err);
          Auth.logOut();
          props.history.replace("/signin");
        }
      }
    }, []);
    if (user) {
      return <AuthComponent history={props.history} user={user} {...props} />;
    } else {
      return null;
    }
  };
  return withRouter(AuthWrapped);
}
