import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import withAuth from './withAuth'
import { AppContext } from './AppProvider.jsx'
import AuthService from './AuthService'
import WithLoading from './WithLoading'
import Pagination from './Pagination'
import RandomRaffleSelector from './RandomRaffleSelector'
import ActionButton from './components/ActionButton.jsx'


const Auth = AuthService.getInstance()
export default withAuth(function RaffleParticipants(props) {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const [statusMessage, setStatusMessage] = useState()
  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }
  useEffect(() => {
    if (update) {
      setLoading(true)
      try {
        Auth.fetch(`/verlosung/teilnehmer`)
          .then(data => {
            dispatch({
              type: 'FETCH_RAFFLEPARTICIPANTS', data: data.rows
                .sort((a, b) => new Date(b.value.createdAt) - new Date(a.value.createdAt))
            })
            setLoading(false)
            setUpdate(false)
          })
          .catch(error => console.error(error))
      } catch (error) {
        console.error(error)
        setLoading(false)
        setUpdate(false)
      }
      return () => {
        setLoading(false);
        setUpdate(false)
      };
    }  
  }, [update, dispatch])

  useEffect(() => {
    setUpdate(true)
  }, [])

  const removeWinner = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/remove/${id}`, { method: 'POST' })
    setStatusMessage(`Gewinner zurückgesetzt.`)
    return setUpdate(true)
  }

  const blockParticipant = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/block/${id}`, { method: 'POST' })
    setStatusMessage(`Teilnehmer blockiert.`)
    return setUpdate(true)
  }

  const unblockParticipant = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/unblock/${id}`, { method: 'POST' })
    setStatusMessage(`Blockierung aufgehoben.`)
    return setUpdate(true)
  }

  let all = state.confirmed
  /*   all = filter === '' ? all : all.filter(i =>
      i.value.email.toLowerCase().replace(/\s/g, '').indexOf(filter.toLowerCase().replace(/\s/g, '')) !== -1 ||
      i.value.lastname.toLowerCase().replace(/\s/g, '').indexOf(filter.toLowerCase().replace(/\s/g, '')) !== -1
    ) */
  all = all && all.slice(state.offset, state.offset + state.limit)

  return (
    <div className="container-fluid">
      {/*       <div className="row">
        <div className="col-md-9">
          <div className="w-100 mr-3 mb-3 mb-md-0">
            <input
              autoFocus={true}
              className="form-control"
              type="text"
              placeholder="Filter by email or name"
              onChange={e => setFilter(e.target.value)}
            />
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-3 order-md-2">
          <div className="row justify-content-center fixed-height-100">
            {statusMessage &&
              <div className="alert alert-info text-center" role="alert">
                {statusMessage}
              </div>
            }
          </div>
          <RandomRaffleSelector
              setUpdate={setUpdate}
              setLoading={setLoading}
              setStatusMessage={setStatusMessage}
            blockedPage={false} />
        </div>
        <div className="col-md-9 order-md-1">
          <WithLoading loading={loading} waitfor={all}>
            {state.confirmed && <Pagination items={state.confirmed} />}
            <table className="table table-sm raffle table-responsive">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>E-Mail</th>
                  <th>Angemeldet seit</th>
                  <th>Status</th>
                  <th>Geburtsdatum</th>
                </tr>
              </thead>
              <tbody id="participant-table">
                {state.confirmed && state.confirmed.length > 0
                  && all.map(participant => (
                    <React.Fragment key={participant.id}>
                      <tr className={participant && participant.value.status === "winner" ? "bg-info winner-all" :
                        participant.value.status === "blocked" ? "bg-warning blocked" : "confirmed"}>
                        <td className='action'>
                          <ActionButton
                            type={`${participant.value.status === "winner" ? "remove" :
                              participant.value.status === "blocked" ? "restore" :
                                "block"}`}
                            onClick={(e) =>
                              participant.value.status === "winner" ? removeWinner(e, participant.id) :
                                participant.value.status === "blocked" ? unblockParticipant(e, participant.id) :
                                  blockParticipant(e, participant.id, participant.key)}
                            title={`${participant.value.status === "winner" ? "Gewinner entfernen" :
                              participant.value.status === "blocked" ? "Blockierung aufheben." :
                                "Teilnehmer blockieren"}`} />
                        </td>
                        <td className='name'>{participant &&
                          <a href={`/verlosung/teilnehmer/${participant.id}`}>
                            {participant.value.firstname} {participant.value.lastname}
                          </a>}
                        </td>
                        <td className='email'>
                          {participant && <a href={`mailto:${participant.value.email}`} >{participant.value.email}</a>}
                        </td>
                        <td className='date'>{participant && new Intl.DateTimeFormat("de-DE", dateOptions).format(Date.parse(participant.value.createdAt))}</td>
                        <td className='status'>{participant && participant.value.status}</td>
                        {/*                         <td className='bg-number'>{participant && participant.value.bgNumber}</td> */}
                        <td className='birthdate'>{participant && participant.value.birthdate}</td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
            {state.confirmed && <Pagination items={state.confirmed} />}
          </WithLoading>
        </div>
      </div>
    </div>
  )
})
