import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import withAuth from './withAuth.js'
import { AppContext } from './AppProvider.jsx'
import AuthService from './AuthService.jsx'
import WithLoading from './WithLoading.jsx'
import Pagination from './Pagination.jsx'
import WinnerControls from './WinnerControls.jsx'
import ActionButton from './components/ActionButton.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEllipsis, faGear, faXmark } from '@fortawesome/free-solid-svg-icons'


const Auth = AuthService.getInstance()
export default withAuth(function RaffleWinners(props) {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const [statusMessage, setStatusMessage] = useState()
  const [filterNewData, setFilterNewData] = useState(false)
  const [emailReady, setEmailReady] = useState([])
  const [emailReminder, setEmailReminder] = useState([])
  const [uncheckedData, setUncheckedData] = useState([])
  const [readyForExport, setReadyForExport] = useState([])
  const [feedbackMailSent, setFeedbackMailSent] = useState([])
  const [exported, setExported] = useState([])
  const [expired, setExpired] = useState([])
  const [filter, setFilter] = useState('')
  //set timeLimit to 7 days
  const timeLimit = (7 * 24 * 60 * 60 * 1000)
  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }
  useEffect(() => {
    if (update) {
      setLoading(true)
      try {
        Auth.fetch(`/verlosung/gewinner`)
          .then(data => {
            dispatch({ type: 'FETCH_RAFFLEWINNERS', data: data.rows })
            setLoading(false)
            setUpdate(false)
          })
          .catch(error => console.error(error))
      } catch (error) {
        console.error(error)
        setLoading(false)
        setUpdate(false)
      }

    }
  }, [update, dispatch])

  useEffect(() => {
    setUpdate(true)
  }, [])

  useEffect(() => {
    let emailReady = []
    let emailReminder = []
    let uncheckedData = []
    let exportReady = []
    let exportedWinners = []
    let feedbackMailSent = []
    let expired = []

    state.winners.map(winner => {
      // filter for uncontacted users
      if (winner.doc.emailStatus && winner.doc.emailStatus === "ready_to_send") {
        emailReady.push(winner)
      }
      // filter for contacted users that didn't respond
      if (winner.doc.emailStatus === "email_sent" && (!winner.doc.jobcenterDocumentProvided && !winner.doc.IBANprovided)) {
        //ignore users that already were reminded
        if (!winner.doc.reminderSent) {
          emailReminder.push(emailReminder)
        }
      }
      if (winner.doc.winnerDrawnAt && winner.doc.emailStatus === "email_sent" && (!winner.doc.jobcenterDocumentProvided && !winner.doc.IBANprovided)) {
        const winnerDate = new Date(winner.doc.winnerDrawnAt).getTime()
        const expirationDate = new Date(winnerDate + timeLimit)
        const today = new Date()
        if (today > expirationDate) {
          expired.push(winner)
        }
      }
      // filter for unchecked user data
      if (winner.doc.jobcenterDocumentProvided && !winner.doc.approved && !winner.doc.rejected) {
        uncheckedData.push(winner)
      }
      // filter for approved winners to Export
      if (winner.doc.approved && !winner.doc.exported) {
        exportReady.push(winner)
      }
      if (winner.doc.approved && winner.doc.exported && !winner.doc.feedbackMailSent) {
        exportedWinners.push(winner)
      }
      if (winner.doc.approved && winner.doc.exported && winner.doc.feedbackMailSent) {
        feedbackMailSent.push(winner)
      }
      setEmailReady(emailReady)
      setEmailReminder(emailReminder)
      setUncheckedData(uncheckedData)
      setReadyForExport(exportReady)
      setExported(exportedWinners)
      setFeedbackMailSent(feedbackMailSent)
      setExpired(expired)
    })
  }, [loading])

  useEffect(() => {
    if (uncheckedData < 1) {
      setFilterNewData(false)
    }
  }, [uncheckedData])

  const removeWinner = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/remove/${id}`, { method: 'POST' })
    setStatusMessage(`Gewinner zurückgesetzt.`)
    return setUpdate(true)
  }

  const approveWinner = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/approve/${id}`, { method: 'POST' })
    setStatusMessage(`Gewinner freigegeben.`)
    return setUpdate(true)
  }

  const rejectWinner = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/reject/${id}`, { method: 'POST' })
    setStatusMessage(`Gewinner abgelehnt.`)
    return setUpdate(true)
  }

  const downloadAttachment = async (id, fileName) => {
    const url = '/download/' + encodeURIComponent(id) + '/' + encodeURIComponent(fileName)
    try {
      const response = await Auth.fetch(url)
      const data = response.data.data

      const uint8Array = new Uint8Array(data);

      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: 'application/octet-stream' });

      // Create a download link
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = fileName;

      // Trigger the download
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading attachment:', error);
    }
  };

  const handleDownloadClick = (id, fileName) => {
    downloadAttachment(id, fileName);
  };

  let winners = state.winners
  //Filter either email or firstname + lastname
  winners = filter === '' ? winners : winners.filter(i =>
    i.doc.email.toLowerCase().replace(/\s/g, '').indexOf(filter.toLowerCase().replace(/\s/g, '')) !== -1 ||
    `${i.doc.firstname.toLowerCase().replace(/\s/g, '') + ' ' + i.doc.lastname.toLowerCase().replace(/\s/g, '')}`.indexOf(filter.toLowerCase()) !== -1
  )
  winners = winners && winners.slice(state.offset, state.offset + state.limit)
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 order-md-2">
          <div className="fixed-height-100">
            {statusMessage &&
              <div className="alert alert-info text-center" role="alert">
                {statusMessage}
              </div>
            }
          </div>
          <WinnerControls
            setUpdate={setUpdate}
            setLoading={setLoading}
            setStatusMessage={setStatusMessage}
            emailReady={emailReady}
            emailReminder={emailReminder}
            uncheckedData={uncheckedData}
            readyForExport={readyForExport}
            feedbackMailSent={feedbackMailSent}
            filterNewData={filterNewData}
            setFilterNewData={setFilterNewData}
            exported={exported}
            setExported={setExported}
            expired={expired} />
        </div>
        <div className="col-md-8 order-md-1">
          <div className={`d-flex align-items-center form-group flex-wrap flex-md-nowrap`}>
            <div className="w-100 mr-3 mb-3 mb-md-0">
              <input
                autoFocus={true}
                className="form-control"
                type="text"
                placeholder="Name oder E-Mail-Adresse suchen"
                onChange={e => setFilter(e.target.value)}
              />
            </div>
          </div>
          <WithLoading loading={loading} waitfor={winners}>
            {state.winners && <Pagination items={state.winners} />}
            <table className="table table-sm raffle table-responsive">
              <thead>
                <tr>
                  <th></th>
                  <th className=''>Name</th>
                  <th className=''>E-Mail</th>
                  <th className=''>Ausgelost am</th>
                  <th className="px-1"></th>
                  <th className="fixed-width winner-header">Kontaktiert</th>
                  <th className="fixed-width winner-header">Nachweis</th>
                  <th className="fixed-width winner-header">Freigabe</th>
                  <th className="fixed-width winner-header">Export</th>
                  <th className="fixed-width winner-header px-4 text-center">Daten verwalten</th>
                  <th className="fixed-width winner-header">Feedback</th>
                </tr>
              </thead>
              <tbody id="participant-table">
                {filterNewData && uncheckedData.map(participant => (
                  <React.Fragment key={participant.doc._id}>
                    <tr className={participant && participant.doc.status === "winner" ? "winner" : ""}>
                      <td className='action'>
                        <ActionButton
                          type="remove"
                          onClick={(e) => removeWinner(e, participant.doc._id)}
                          title="Gewinner zurücksetzen"
                        />
                      </td>
                      <td className='name'>{participant && <a href={`/verlosung/teilnehmer/${participant.doc._id}`}>{participant.doc.firstname} {participant.doc.lastname}</a>}</td>
                      <td className='email'>
                        <a href={`mailto:${participant.doc.email}`} >{participant.doc.email}</a>
                      </td>
                      <td className='date'>{participant && participant.doc.winnerDrawnAt && new Intl.DateTimeFormat("de-DE", dateOptions).format(Date.parse(participant.doc.winnerDrawnAt))}</td>
                      <td></td>
                      <td className={`center text-light
                          ${participant.doc.emailStatus === "ready_to_send" ? "bg-white" :
                        participant.doc.emailStatus === "queued" || participant.doc.emailStatus === "reminder_queued" ? "bg-warning" :
                            participant.doc.emailStatus === "email_sent" ? "bg-success" : ""}`}>
                        <FontAwesomeIcon
                          icon={participant.doc.emailStatus === "ready_to_send" ? faEllipsis :
                            participant.doc.emailStatus === "queued" || participant.doc.emailStatus === "reminder_queued" ? faGear :
                              participant.doc.emailStatus === "email_sent" ? faCheck : faEllipsis}
                          {...(participant.doc.emailStatus === "queued" || participant.doc.emailStatus === "reminder_queued" || participant.doc.emailStatus === "feedback_queued") && { spin: true }}
                        />
                      </td>
                      <td className={`center text-light ${participant.doc.emailStatus === "email_sent" ? participant.doc.jobcenterDocumentProvided ? "bg-success" : "bg-warning" : ""}`}>
                        {participant.doc.emailStatus === "email_sent" &&
                          <FontAwesomeIcon icon={participant.doc.jobcenterDocumentProvided ? faCheck : faEllipsis}
                            {...!participant.doc.jobcenterDocumentProvided && { beatFade: true }} />
                        }</td>
                      <td className={`center text-light 
                            ${(participant.doc.jobcenterDocumentProvided && !participant.doc.approved && !participant.doc.rejected) ? "bg-warning" :
                          participant.doc.approved ? "bg-success" :
                            participant.doc.rejected ? "bg-danger" : ""}`}>
                        {participant.doc.jobcenterDocumentProvided &&
                          <FontAwesomeIcon icon={participant.doc.approved ? faCheck :
                            participant.doc.rejected ? faXmark : faEllipsis}
                          {...participant.doc.jobcenterDocumentProvided && !participant.doc.approved && !participant.doc.rejected && { beatFade: true }}
                          />}
                      </td>
                      <td className={`center text-light 
                            ${(participant.doc.approved && !participant.doc.exported) ? "bg-warning" :
                          participant.doc.exported ? "bg-success" : ""}`}>
                        {(participant.doc.approved) &&
                          <FontAwesomeIcon icon={participant.doc.exported ? faCheck : faEllipsis}
                            {...participant.doc.approved && !participant.doc.exported && { beatFade: true }} />}
                      </td>
                      <td className={`center buttonrow ${participant.doc.dataProvided ? "d-flex justify-content-start align-items-center" : "d-none"}`}>
                        {participant.doc._attachments && Object.keys(participant.doc._attachments).map(attachment => (
                          <>
                            <ActionButton
                              type="download"
                              onClick={() => handleDownloadClick(participant.doc._id, attachment)}
                              title="Leistungsbescheid herunterladen"
                              className="mr-3" />
                          </>
                        ))}
                        {participant.doc.jobcenterDocumentProvided &&
                          <>
                            {/* <ActionButton
                              type="download"
                              onClick={() => handleDownloadClick(participant.doc._id, Object.keys(participant.doc._attachments)[0])}
                              title="Leistungsbescheid herunterladen"
                              className="mx-3" /> */}
                            <ActionButton
                              type="reject"
                              onClick={(e) => rejectWinner(e, participant.doc._id)}
                              title="Leistungsbescheid ablehnen"
                              className="mr-3"
                              disabled={(participant.doc.approved || participant.doc.rejected) ? true : false} />
                            <ActionButton
                              type="approve"
                              onClick={(e) => approveWinner(e, participant.doc._id)}
                              title="Leistungsbescheid freigeben"
                              className="mr-3"
                              disabled={(participant.doc.approved || participant.doc.rejected) ? true : false} />
                          </>
                        }
                      </td>
                      <td className={`center text-light 
                            ${(participant.doc.exported && !participant.doc.feedbackMailSent) ? "bg-warning" :
                          participant.doc.feedbackMailSent ? "bg-success" : ""}`}>
                        {(participant.doc.exported) &&
                          <FontAwesomeIcon icon={participant.doc.feedbackMailSent ? faCheck : faEllipsis}
                            {...participant.doc.exported && !participant.doc.feedbackMailSent && { beatFade: true }} />}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
                {!filterNewData && state.winners && state.winners.length > 0
                  && winners.map(participant => (
                    <React.Fragment key={participant.doc._id}>
                      <tr className={participant && participant.doc.status === "winner" ? "winner" : ""}>
                        <td className='action'>
                          <ActionButton
                            type="remove"
                            onClick={(e) => removeWinner(e, participant.doc._id)}
                            title="Gewinner zurücksetzen"
                          />
                        </td>
                        <td className='name'>{participant && <a href={`/verlosung/teilnehmer/${participant.doc._id}`}>{participant.doc.firstname} {participant.doc.lastname}</a>}</td>
                        <td className='email'>
                          <a href={`mailto:${participant.doc.email}`} >{participant.doc.email}</a>
                        </td>
                        <td className='date'>{participant && participant.doc.winnerDrawnAt && new Intl.DateTimeFormat("de-DE", dateOptions).format(Date.parse(participant.doc.winnerDrawnAt))}</td>
                        <td></td>
                        <td className={`center text-light
                          ${participant.doc.emailStatus === "ready_to_send" ? "bg-white" :
                          participant.doc.emailStatus === "queued" || participant.doc.emailStatus === "reminder_queued" || participant.doc.emailStatus === "feedback_queued" ? "bg-warning" :
                              participant.doc.emailStatus === "email_sent" ? "bg-success" : ""}`}>
                          <FontAwesomeIcon
                            icon={participant.doc.emailStatus === "ready_to_send" ? faEllipsis :
                              participant.doc.emailStatus === "queued" || participant.doc.emailStatus === "reminder_queued" || participant.doc.emailStatus === "feedback_queued" ? faGear :
                                participant.doc.emailStatus === "email_sent" ? faCheck : faEllipsis}
                            {...(participant.doc.emailStatus === "queued" || participant.doc.emailStatus === "reminder_queued" || participant.doc.emailStatus === "feedback_queued") && { spin: true }}
                          />
                        </td>
                        <td className={`center text-light ${participant.doc.emailStatus === "email_sent" ? participant.doc.dataProvided ? "bg-success" : "bg-warning" : ""}`}>
                          {participant.doc.emailStatus === "email_sent" &&
                            <FontAwesomeIcon icon={participant.doc.dataProvided ? faCheck : faEllipsis}
                              {...!participant.doc.dataProvided && { beatFade: true }} />
                          }</td>
                        <td className={`center text-light 
                            ${(participant.doc.dataProvided && !participant.doc.approved && !participant.doc.rejected) ? "bg-warning" :
                            participant.doc.approved ? "bg-success" :
                              participant.doc.rejected ? "bg-danger" : ""}`}>
                          {participant.doc.dataProvided &&
                            <FontAwesomeIcon icon={participant.doc.approved ? faCheck :
                              participant.doc.rejected ? faXmark : faEllipsis}
                              {...participant.doc.dataProvided && !participant.doc.approved && !participant.doc.rejected && { beatFade: true }}
                            />}
                        </td>
                        <td className={`center text-light 
                            ${(participant.doc.approved && !participant.doc.exported) ? "bg-warning" :
                            participant.doc.exported ? "bg-success" : ""}`}>
                          {(participant.doc.approved) &&
                            <FontAwesomeIcon icon={participant.doc.exported ? faCheck : faEllipsis}
                              {...participant.doc.approved && !participant.doc.exported && { beatFade: true }} />}
                        </td>
                        <td className={`center buttonrow ${participant.doc.dataProvided ? "d-flex justify-content-start align-items-center" : "d-none"}`}>
                          {participant.doc._attachments && Object.keys(participant.doc._attachments).map(attachment => (
                            <>
                              <ActionButton
                                type="download"
                                onClick={() => handleDownloadClick(participant.doc._id, attachment)}
                                title="Leistungsbescheid herunterladen"
                                className="mr-3" />
                            </>
                          ))}

                          {participant.doc.dataProvided &&
                            <>
                              {/* <ActionButton
                                type="download"
                                onClick={() => handleDownloadClick(participant.doc._id, Object.keys(participant.doc._attachments)[0])}
                                title="Leistungsbescheid herunterladen"
                                className="mx-3" /> */}
                              <ActionButton
                                type="reject"
                                onClick={(e) => rejectWinner(e, participant.doc._id)}
                                title="Leistungsbescheid ablehnen"
                                className="mr-3"
                                disabled={(participant.doc.approved || participant.doc.rejected) ? true : false} />
                              <ActionButton
                                type="approve"
                                onClick={(e) => approveWinner(e, participant.doc._id)}
                                title="Leistungsbescheid freigeben"
                                className="mr-3"
                                disabled={(participant.doc.approved || participant.doc.rejected) ? true : false} />
                            </>
                          }
                        </td>
                        <td className={`center text-light 
                            ${(participant.doc.exported && !participant.doc.feedbackMailSent) ? "bg-warning" :
                            participant.doc.feedbackMailSent ? "bg-success" : ""}`}>
                          {(participant.doc.exported) &&
                            <FontAwesomeIcon icon={participant.doc.feedbackMailSent ? faCheck : faEllipsis}
                              {...participant.doc.exported && !participant.doc.feedbackMailSent && { beatFade: true }} />}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
            {state.winners && <Pagination items={state.winners} />}
          </WithLoading>
        </div>
      </div>
    </div>
  )
})
