import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import withAuth from './withAuth'
import { AppContext } from './AppProvider.jsx'
import { downloadHB } from './helper.js'
import AuthService from './AuthService'
import WithLoading from './WithLoading'
import TableRow from './TableRow'

const Auth = AuthService.getInstance()
export default withAuth(function BankDonations (props) {
  const [open, setOpen] = useState([])
  const [filterby, setfilterby] = useState((doc) => doc)
  const [loading, setloading] = useState(false)
  const [statusMessage, setStatusMessage] = useState()
  const { state, dispatch } = useContext(AppContext)
  useEffect(() => {
    if (state.bankdonations.length === 0) {
      setloading(true)
      Auth.fetch(`/donationreceipts/list`).then(data => {
        dispatch({ type: 'FETCH_BANKDONATIONS', data: data })
        setloading(false)
      })
    }
  }, [])
  const filterFunction = (what) => {
    setloading(true)
    setfilterby(what)
    setloading(false)
  }

  const handleExport = async (e) => {
    setStatusMessage(null)
    setloading(true)
    setStatusMessage('Preparing data for bulk export. This might take a while.')
    try {
      await downloadHB(e).then(data => data && setStatusMessage(`Success! Downloading ${data.statusText} donation receipts.`))
    } catch (err) {
      setStatusMessage(String(err))
      setloading(false)
    }
    setloading(false)
  }

  let localDonations = filterby === "pdf"
  ? Object.entries(state.bankdonations).filter((bankDonation) => state.fullhartzbreaker.find(hb => hb.doc.profile.bank.mrn === bankDonation[0]) && state.fullhartzbreaker.find(hb => hb.doc.profile.bank.mrn === bankDonation[0]).doc._attachments)
  : filterby === "billed" 
  ? Object.entries(state.bankdonations).filter((bankDonation) => state.fullhartzbreaker.find(hb => hb.doc.profile.bank.mrn === bankDonation[0]) && state.fullhartzbreaker.find(hb => hb.doc.profile.bank.mrn === bankDonation[0]).doc.profile.bank.billed)
  : Object.entries(state.bankdonations)
  
  {/* TODO we currently display the year buttons depending just if there are donations in this year, but not if the donation also contains a pdf. We should refilter based on attachment key eg. 2018.pdf */}
  var all = [...new Set(Object.entries(state.bankdonations).map(donator => donator[1].reduce((acc, row) => [...acc, row.doc.BookgDt.match(/\d{4}/)[0]], []).filter((v, i, a) => a.indexOf(v) === i)))]
  var coll = new Set()
  all.map((item) => item.map(it => coll.add(it)))
  coll = [...coll]
  
  return (
    <div className="container-fluid">
      {statusMessage && <div className="row">
        <div className="col-8 offset-2">
          <div className="alert alert-info text-center" role="alert">
            {statusMessage}
          </div>
        </div>
      </div>}
      <div className="row">
        <div className="col-md-12">
          <WithLoading loading={loading} waitfor={state.bankdonations}>
            <div className="d-md-flex justify-content-between">
              <div className="mr-3 my-4 my-md-0">
                <h4>Filter</h4>
              <button
                type="button"
                className={`btn btn-sm mr-1 ${filterby === undefined ? ` btn-primary` : `btn-outline-primary`}`}
                onClick={(e) => filterFunction(undefined)}
              >
                All
                </button>
              <button
                type="button"
                className={`btn btn-sm mr-1 ${filterby === "pdf" ? ` btn-primary` : `btn-outline-primary`}`}
                onClick={(e) => filterFunction("pdf")}
              >
                PDF
                </button>
              <button
                type="button"
                  className={`btn btn-sm mr-1 ${filterby === "billed" ? ` btn-primary` : `btn-outline-primary`}`}
                onClick={(e) => filterFunction("billed")}
              >
                Billed
                </button>
            </div>
              <div className="mr-3 my-4 my-md-0">
                <h4>Download new donation receipts</h4>
              <div className="d-flex">
                {coll.map((year, index) => (
                  <div className="d-flex" key={index}>
                    <button className="btn btn-outline-primary no-wrap btn-sm mr-1" data-confirm={`This action marks all new entries as billed for ${year}. They will not appear in the next export. Okay?`} data-type={`/generate/donationreceipts?year=${year}&billed=true`} onClick={(e) => handleExport(e)}>
                      {year}
                    </button>
                  </div>
                ))}
                </div>
              </div>
              <div className="mr-3 my-4 my-md-0">
                <h4>Download all donation receipts</h4>
                <div className="d-flex">
                  {coll.map((year, index) => (
                    <div className="d-flex" key={index}>
                      <button className="btn btn-outline-primary no-wrap btn-sm mr-1" data-type={`/generate/donationreceipts?year=${year}`} onClick={(e) => handleExport(e)}>
                        {year}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <table className="table table-sm mt-3 bankdonations table-responsive">
              <thead>
              <tr>
                <th>Datum</th>
                <th>Name</th>
                <th>MndtId</th>
                <th>IBAN</th>
                <th>Info</th>
                <th>Amt</th>
              </tr>
              </thead>
              <tbody>
              {state.bankdonations && localDonations.map(([mrn, bankDonations], index1) => (
                <React.Fragment key={index1}>
                  <TableRow
                    profile={state.fullhartzbreaker.find(hb => hb.doc.profile.bank.mrn === bankDonations[0].doc.MndtId)}
                    open={open}
                    setOpen={setOpen}
                    header

                    doc={bankDonations[0].doc}
                  />
                  {bankDonations.slice(1, bankDonations.length).map(({ doc }, index) => (
                    <TableRow setOpen={setOpen} open={open} key={index} doc={doc}/>
                  ))}
                </React.Fragment>
              ))}
              </tbody>
            </table>
          </WithLoading>
        </div>
      </div>
    </div>
  )
})
