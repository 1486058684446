import React, { useReducer, useEffect, useState, useContext } from 'react'
import './App.css'
import moment from 'moment'
import AuthService from './AuthService.jsx'
import { AppContext } from './AppProvider.jsx'
import logo from './logo-spinning.svg'
import { ReactComponent as Fax } from './logo-spinning.svg'

const Auth = AuthService.getInstance()

const BlogPost = props => {
  const { state, dispatch } = useContext(AppContext)
  return (
    <tr key={props.doc._id}>
      <th>{props.doc.createdAt ? moment(props.doc.createdAt).format('DD.MM.YYYY') : ` - `}</th>
      <td className={`w-100`}>
        <p className="d-inline-block text-truncate">{props.doc.title}</p>
      </td>
      <td className={`text-center`}>
        {props.doc._attachments ? <img className={`mh-75 text-light`}
                                       src={`data:image/png;base64,${props.doc._attachments['image'].data}`}
                                       alt={props.doc._id}/> : <Fax className={`text-light`}/>}
      </td>
      <td>
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => props.onPostEdit(props.doc)}
          >
            edit
          </button>
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() =>
              dispatch({
                type: 'TOGGLE_TOAST',
                data: {
                  open: true,
                  type: `success`,
                  message: `Sure?`,
                  actions: [
                    {
                      callback: e => props.onPostDelete(props.doc),
                      title: 'Ok',
                    },
                  ],
                },
              })
            }
          >
            delete
          </button>
        </div>
      </td>
    </tr>
  )
}
export default BlogPost
