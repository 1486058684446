import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import BlogPost from './BlogPost.jsx'
import { AppContext } from './AppProvider.jsx'
import AuthService from './AuthService.jsx'
import withAuth from './withAuth'
import WithLoading from './WithLoading'

const Auth = AuthService.getInstance()
const Blog = function (props) {
  const { state, dispatch } = useContext(AppContext)
  const [doc, setdoc] = useState({}) // '' is the initial state value
  const [loading, setloading] = useState(true)
  useEffect(() => {
    if (state.articles.length === 0) {
      Auth.fetch(`/articles`).then(data => {
        dispatch({ type: 'FETCH_ARTICLES', data: data })
        // dispatch({
        //   type: 'TOGGLE_TOAST',
        //   data: { open: true, type: `success`, message: `Articles fetched` },
        // })
        setloading(false)
      })
    } else {
      setloading(false)
    }
  }, [])
  const handleImageChange = e => {
    covertAntSetImage(e.target.files[0])
  }
  const onPostEdit = newDoc => {
    setdoc(newDoc)
  }
  const covertAntSetImage = data => {
    data.error ? console.error(data.message) : data.arrayBuffer().then(buffer => {
      const bufferFile = Buffer.from(buffer).toString('base64')
      setdoc({ ...doc, _attachments: { image: { data: bufferFile } } })
    })
  }
  const onPostDelete = post => {
    Auth.fetch(`/articles/${post._id}`, {
      method: 'DELETE',
      body: JSON.stringify({ rev: post._rev }),
    }).then(data => {
      dispatch({ type: 'REMOVE_ARTICLE', data: data })
      dispatch({
        type: 'TOGGLE_TOAST',
        data: {
          open: true,
          type: `success`,
          message: `Post ${post._id} deleted!`,
        },
      })
    })
  }
  const onPostSubmit = event => {
    event.preventDefault()

    var data = new FormData()
    var imagedata = document.querySelector('input[type="file"]').files[0]
    let { ...postDoc } = doc
    if (imagedata) {
      data.append('image', imagedata, imagedata.name)
    }
    const [...articles] = state.articles
    if (doc._id) {
      postDoc._id = doc._id
    }
    if (doc._rev) {
      postDoc._rev = doc._rev
    } else {
    }
    var articleIndex = articles.findIndex(obj => obj._id === doc._id)
    articles.splice(articleIndex === -1 ? articles.length : articleIndex, 1, doc)
    data.append('doc', JSON.stringify(postDoc))
    Auth.fetch(`/articles`, {
      method: 'POST',
      body: data,
    }, true).then(res => {
      if (res.error) {
        dispatch({
          type: 'TOGGLE_TOAST',
          data: {
            open: true,
            type: `danger`,
            message: `Error: ${JSON.stringify(res.message)}`,
          },
        })
      } else {
        dispatch({
          type: 'FETCH_ARTICLES',
          data: articles
        })
        dispatch({
          type: 'TOGGLE_TOAST',
          data: {
            open: true,
            type: `success`,
            message: doc._rev ? `Updated` : `Created`,
          },
        })
        setdoc({})
      }
    })
      .catch(e => {
        console.debug(e)
        dispatch({
          type: 'TOGGLE_TOAST',
          data: {
            open: true,
            type: `danger`,
            message: `Error: ${JSON.stringify(e)}`,
          },
        })
      })
  }
  const posttemplate = (
    <div className="table-responsive ">
      <WithLoading loading={loading} waitfor={state.articles}>
        <table className="py-5 table table-striped table-sm text-left ">
          <thead>
          <tr>
            <th scope="col">Created</th>
            <th scope="col">Title</th>
            <th colSpan="2" scope="col">
              Image
            </th>
          </tr>
          </thead>
          <tbody>
          {state.articles &&
          state.articles.map(doc => (
            <BlogPost
              key={doc._id}
              onPostEdit={onPostEdit}
              onPostDelete={onPostDelete}
              doc={doc}
            />
          ))}
          </tbody>
        </table>
      </WithLoading>
    </div>
  )
  return (
    <div className="container-fluid">
      <div className="text-center row">
        <div className="col-md-6">{posttemplate}</div>
        <div className="col-md-6">
          <form onSubmit={onPostSubmit} className="form-post mb-5">
            <div className="mb-3">
              <label htmlFor="title" className="sr-only">
                Title
              </label>
              <input
                value={doc.title || ''}
                onChange={e => setdoc({ ...doc, title: e.target.value })}
                type="text"
                id="Title"
                className="form-control"
                placeholder="Name"
                autoFocus
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="content" className="sr-only">
                Content
              </label>
              <textarea
                value={doc.content || ''}
                onChange={e => setdoc({ ...doc, content: e.target.value })}
                id="content"
                className="form-control"
                placeholder="Content"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="content" className="sr-only">
                File
              </label>
              <input
                type="file"
                name="image"
                onChange={handleImageChange}
                className="form-control"
                placeholder="Image"
              />
            </div>
            {doc._attachments && (
              <img className="img-fluid" src={`data:image/png;base64,${doc._attachments.image.data}`}/>
            )}
            <button type="submit" className="btn btn-lg btn-primary btn-block">Submit</button>
            {doc._id &&
            <button onClick={e => setdoc({})} className="btn btn-lg btn-outline-danger mt-3 btn-block">Cancel</button>}
          </form>
        </div>
      </div>
    </div>
  )
}
export default withAuth(Blog)
