import React, { useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom'

import AuthService from './AuthService'
import { AppContext } from './AppProvider'

const Auth = AuthService.getInstance()
const Hartzbreaker = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const [doc, setDoc] = useState({})
  const [loading, setLoading] = useState(false)
  const [availableDonations, setAvailableDonations] = useState({})
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    Auth.fetch(`/hartzbreaker/${props.match.params.id}`).then(({ payload, pastDonations }) => {
      const groupedByYear = pastDonations.rows.reduce(function (rv, x) {
        (rv[x.doc['BookgDt'].substr(0, 4)] = rv[x.doc['BookgDt'].substr(0, 4)] || []).push(x.doc)
        return rv
      }, [])
      setAvailableDonations(groupedByYear)
      setDoc(payload)
    }).catch(e => {
      dispatch({
        type: 'TOGGLE_TOAST',
        data: {
          open: true,
          type: `danger`,
          message: `Error: ${JSON.stringify(e)}`,
        },
      })
    })
  }, [])

  useEffect(() => {
    Auth.fetch(`/hartzbreaker/${props.match.params.id}`).then(({ payload }) => {
      setDoc(payload)
    }).catch(e => {
      dispatch({
        type: 'TOGGLE_TOAST',
        data: {
          open: true,
          type: `danger`,
          message: `Error: ${JSON.stringify(e)}`,
        },
      })
    })
  }, [loading])
  return (
    <div className="container-fluid" onClick={() => setEdit(false)}>
      {doc.profile && (
        <div className="row">
          <div className="col-md-3">
            <div className="d-flex justify-content-between mb-2">
              <div className="">
                <NavLink
                  to={`/hartzbreaker`}
                  className="btn btn-outline-primary"
                  activeClassName=""
                >
                  Zurück
                </NavLink>
                {/* <button
                  className={`btn ml-3 ${doc.profile.membership.pausedAt ? `btn-warning` : `btn-success`}`}
                  disabled={loading}
                  onClick={() => {
                    setLoading(!loading)
                  }}>
                  loading
                </button> */}
                <button
                  className={`btn ml-3 ${doc.profile.membership.pausedAt ? `btn-warning` : `btn-success`}`}
                  disabled={loading}
                  onClick={() => {
                    setLoading(true)
                    Auth.fetch(`/hartzbreaker/${encodeURIComponent(doc._id)}/pause`, {
                      method: 'post',
                    }).then(data => {
                      setLoading(false)
                      doc.profile.membership.pausedAt = data.doc.profile.membership.pausedAt
                      doc.profile.membership.pausedReason = data.doc.profile.membership.pausedReason
                      setDoc(doc)
                      dispatch({
                        type: 'TOGGLE_TOAST',
                        data: {
                          open: true,
                          type: `success`,
                          message: `Updated`,
                        },
                      })
                    }).catch(e => {
                      setLoading(false)
                      dispatch({
                        type: 'TOGGLE_TOAST',
                        data: {
                          open: true,
                          type: `danger`,
                          message: `Error: ${JSON.stringify(e)}`,
                        },
                      })
                    })
                  }}
                >

                  {!doc.profile.membership.pausedAt ? `Active` : `Paused`}
                </button>


              </div>
            </div>
            <h4>
              <code>
                <a href={`http://127.0.0.1:${window.location.hostname !== "localhost" ? `1` : ``}5984/_utils/#database/hartzbreaker/${encodeURIComponent(doc._id)}`} target="_blank" title={JSON.stringify(doc.name)}>{doc.name}</a>
              </code>
            </h4>
            {/* <img className="mb-2" src={`https://avatars.dicebear.com/v2/male/${doc.name}.svg`} /> */}
            <h4>Donationreceipts</h4>
            <div className="mb-3">
              {availableDonations.constructor === Array && availableDonations.map((donation, year) => (
                <div key={year}>
                  <button
                    key={year}
                    className={`btn mr-3 mb-3 ${doc.blocked_donationyears && doc.blocked_donationyears.includes(year) ? `btn-warning` : `btn-success`}`}
                    disabled={loading || doc._attachments && !!doc._attachments[`${year}.pdf`]}
                    onClick={() => {
                      setLoading(true)
                      Auth.fetch(`/hartzbreaker/${encodeURIComponent(doc._id)}/block`, {
                        method: 'post',
                        body: JSON.stringify(year)
                      }).then(data => {
                        setLoading(false)
                        doc.blocked_donationyears = data.doc.blocked_donationyears
                        setDoc(doc)
                        dispatch({
                          type: 'TOGGLE_TOAST',
                          data: {
                            open: true,
                            type: `success`,
                            message: `Updated`,
                          },
                        })
                      }).catch(e => {
                        setLoading(false)
                        console.log(e);
                        dispatch({
                          type: 'TOGGLE_TOAST',
                          data: {
                            open: true,
                            type: `danger`,
                            message: `Error: ${JSON.stringify(e)}`,
                          },
                        })
                      })
                    }}>
                    <span>{year}</span>
                    {!!doc.blocked_donationyears && doc.blocked_donationyears.includes(year) ? ` Blocked` : ` Block`}
                  </button>

                  {(
                    (!!doc._attachments && !doc._attachments[`${year}.pdf`] && !(doc.blocked_donationyears && doc.blocked_donationyears.includes(year)))
                    || (!doc._attachments && !!availableDonations[year] && !(doc.blocked_donationyears && doc.blocked_donationyears.includes(year)))
                  ) && (
                    <button
                      className="btn btn-outline-primary no-wrap mr-3 mb-3"
                      data-type={`/generate/donationreceipts`}
                      disabled={loading}
                      onClick={() => {
                        setLoading(true)
                        Auth.fetch(`/donationreceipts/pdf?year=${year}`, {
                          method: 'post',
                          body: JSON.stringify({
                            id: doc._id,
                            year: year
                          })
                        }).then(data => {
                          const attachments = { ...doc._attachments, ...data.doc }
                          doc._attachments = attachments
                          setDoc({ _attachments: attachments, ...doc })
                          setLoading(false)
                          dispatch({
                            type: 'TOGGLE_TOAST',
                            data: {
                              open: true,
                              type: `success`,
                              message: `${data.message}`,
                            },
                          })
                        }).catch(e => {
                          setLoading(false)
                          dispatch({
                            type: 'TOGGLE_TOAST',
                            data: {
                              open: true,
                              type: `danger`,
                              message: `Error: ${JSON.stringify(e)}`,
                            },
                          })
                        })
                      }}>Generate {year}</button>
                  )}
                </div>
              ))}
            </div>
            <h5>Existing donationreceipts:</h5>
            <div className={`row`}>
              {doc._attachments && Object.keys(doc._attachments).map((attachment, index) => (
                <div key={index} className={`col-md-12`}>
                  <h5>
                    {attachment}
                    {/*                     <button
                      className={`btn btn-outline-danger ml-2`}
                      disabled={loading}
                      onClick={e => {
                        e.preventDefault()
                        setLoading(true)
                        Auth.fetch(`/hartzbreaker/${props.match.params.id}/${attachment}`, {
                          method: 'post',
                          body: JSON.stringify(attachment)
                        }).then(data => {
                          setLoading(false)
                          setEdit(false)
                          setDoc(data.doc)
                          dispatch({
                            type: 'TOGGLE_TOAST',
                            data: {
                              open: true,
                              type: `success`,
                              message: `Deleted PDF from ${doc.name}`,
                            },
                          })
                        }).catch(e => {
                          setLoading(false)
                          dispatch({
                            type: 'TOGGLE_TOAST',
                            data: {
                              open: true,
                              type: `danger`,
                              message: `Error: ${JSON.stringify(e)}`,
                            },
                          })
                        })
                      }}>Delete PDF
                      </button> */}
                  </h5>
                  <div className="aspect-ratio-a4 position-relative">
                    <embed
                      src={`data:application/pdf;base64,${doc._attachments[attachment].data}`}
                      width="100%"
                      height="280%"
                      className="aspect-ratio-a4-inner"
                      type="application/pdf"
                    />
                  </div>
                </div>
              )
              )}
            </div>
          </div>
          <div className="col-md-9">
            <table className="table table-striped table-sm table-hartzbreaker table-responsive">
              <tbody>
                <tr>
                  <td>newsletter.subscribedAt</td>
                  <td>{doc.newsletter && doc.newsletter.subscribedAt}</td>
                </tr>
                <tr>
                  <td>newsletter.status</td>
                  <td>{doc.newsletter && doc.newsletter.status}</td>
                </tr>
                <tr>
                  <td>street</td>
                  <td>{doc.profile.street}</td>
                </tr>
                <tr>
                  <td>zip</td>
                  <td>{doc.profile.zip}</td>
                </tr>
                <tr>
                  <td>city</td>
                  <td>{doc.profile.city}</td>
                </tr>
                {doc.profile.bank && (
                  <tr>
                    <td>bank.holder</td>
                    <td>{doc.profile.bank && doc.profile.bank.holder}</td>
                  </tr>
                )}
                {doc.profile.bank && (
                  <tr onClick={(e) => {
                    e.stopPropagation()
                    !edit && setEdit(doc.profile.bank.iban)
                  }}>
                    <td>bank.iban</td>
                    <td>{doc.profile.bank && !edit ? <span className={`text-primary`}
                    >{doc.profile.bank.iban}</span> : (
                        <div className={`form-inline`}>
                          <input
                            autoFocus={true}
                            className="form-control flex-grow-1"
                            type="text"
                            placeholder="IBAN"
                            value={edit}
                            onChange={(e) => setEdit(e.target.value)}
                          />
                          <button className={`btn btn-primary`} onClick={e => {
                            e.preventDefault()
                            setLoading(true)
                            Auth.fetch(`/hartzbreaker/${doc._id}/iban`, {
                              method: 'post',
                              body: JSON.stringify(edit.replace(/ /g, '').trim())
                            }).then(data => {
                              setEdit(false)
                              setLoading(false)
                              setDoc(data.doc)
                              dispatch({
                                type: 'TOGGLE_TOAST',
                                data: {
                                  open: true,
                                  type: `success`,
                                  message: `Updated`,
                                },
                              })
                            }).catch(e => {
                              setLoading(false)
                              dispatch({
                                type: 'TOGGLE_TOAST',
                                data: {
                                  open: true,
                                  type: `danger`,
                                  message: `Error: ${JSON.stringify(e)}`,
                                },
                              })
                            })
                          }}>Save
                      </button>
                        </div>
                      )}</td>
                  </tr>

                )}
                <tr>
                  <td>bank.mrn</td>
                  <td>{doc.profile.bank && doc.profile.bank.mrn}</td>
                </tr>
                <tr>
                  <td>donation.sanktionsfrei</td>
                  <td>{doc.profile.donation && doc.profile.donation.sanktionsfrei}</td>
                </tr>
                <tr>
                  <td>donation.hartzbreaker</td>
                  <td>{doc.profile.donation && doc.profile.donation.hartzbreaker}</td>
                </tr>
                <tr>
                  <td>membership.createdAt</td>
                  <td>{doc.profile.membership && doc.profile.membership.createdAt}</td>
                </tr>
                <tr>
                  <td>membership.updatedAt</td>
                  <td>{doc.profile.membership && doc.profile.membership.updatedAt}</td>
                </tr>
                <tr>
                  <td>membership.pausedAt</td>
                  <td>{doc.profile.membership && doc.profile.membership.pausedAt}</td>
                </tr>
                <tr>
                  <td>membership.pausedReason</td>
                  <td>{doc.profile.membership && doc.profile.membership.pausedReason}</td>
                </tr>
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>firstname</td>
                    <td>
                      {
                        doc.profile.raffle.firstname}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>lastname</td>
                    <td>
                      {
                        doc.profile.raffle.lastname}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>street</td>
                    <td>
                      {doc.profile.raffle.street}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>zip</td>
                    <td>
                      {doc.profile.raffle.zip}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>city</td>
                    <td>
                      {doc.profile.raffle.city}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>gender</td>
                    <td>
                      {doc.profile.raffle.gender}
                    </td>
                  </tr>
                )}
                {doc.profile &&
                  doc.profile.raffle && (
                    <tr>
                      <td>education</td>
                      <td>
                        {
                          doc.profile.raffle.education}
                      </td>
                    </tr>
                  )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>family</td>
                    <td>
                      {doc.profile.raffle.family}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>jobtraining</td>
                    <td>
                      {
                        doc.profile.raffle.jobtraining}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>children</td>
                    <td>
                      {
                        doc.profile.raffle.children}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>birthdate</td>
                    <td>
                      {
                        doc.profile.raffle.birthdate}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>token</td>
                    <td>
                      {doc.profile.raffle.token}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>joined</td>
                    <td>
                      {doc.profile.raffle.joined}
                    </td>
                  </tr>
                )}

                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>controlGroup</td>
                    <td>
                      {
                        doc.profile.raffle.won &&
                        doc.profile.raffle.won.controlGroup}
                    </td>
                  </tr>
                )}
                {doc.profile && doc.profile.raffle && (
                  <tr>
                    <td>date</td>
                    <td>
                      {
                        doc.profile.raffle.won &&
                        doc.profile.raffle.won.date}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

          </div>
        </div>
      )}
    </div>
  )
}
export default Hartzbreaker
