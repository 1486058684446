import React, { useState, useEffect, useContext } from 'react'
import AuthService from './AuthService.jsx'
import { AppContext } from './AppProvider.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'

const Auth = AuthService.getInstance()

export default function DuplicateFinder({ setUpdate, setLoading, setStatusMessage, blockedPage, setScanComplete, setDuplicates }, ...props) {
  const { state, dispatch } = useContext(AppContext)
  const winners = state.winners
  const participants = state.confirmed
  const blocked = state.blocked
  const [randomParticipants, setRandomParticipants] = useState([])
  const [winnerAmount, setWinnerAmount] = useState(0)
  const [hoverSpin, setHoverSpin] = useState(false)

  const getDuplicates = async () => {
    let dupl = []
    //loop through participants to look for duplicate birthdays
    for (let i in participants) {
      for (let j in participants) {
        if (participants[i].value.birthdate === participants[j].value.birthdate && i !== j) {
          if (participants[i].value.lastname === participants[j].value.lastname && i !== j) {
          // Check if the found duplicate is already in the duplicates array
            if (!dupl.includes(participants[i])) {
              dupl.push(participants[i]);
              break; // To avoid adding the same duplicate multiple times
            }
          }
        }
      }
    }
    let sorted = await sortDate(dupl)
    await setDuplicates(sorted)
    setScanComplete(true)
  }

  const sortDate = (arr) => {
    arr.sort((a, b) => new Date(b.value.birthdate) - new Date(a.value.birthdate))
    return arr
  }

  const getRandomEntries = (number) => {
    const total = Number(number)
    let randomEntries = []
    for (let i = 0; i < participants.length; i++) {
      if (randomEntries.length < total) {
        const randomIndex = Math.floor(Math.random() * participants.length);
        if (!randomEntries.includes(participants[randomIndex])) {
          randomEntries.push(participants[randomIndex]);
        } else if (randomEntries.includes(participants[randomIndex]) && randomEntries.length < total) {
          //if index has already been used, i-- to keep looping
          i--
        }
      }
    }
    return randomEntries
  }

  const handleChange = (e) => {
    if (e.target.value > participants.length) {
      setWinnerAmount(participants.length)
    } else {
      setWinnerAmount(e.target.value)
    }
  }

  useEffect(() => {
    let trimmed = []
    const results = getRandomEntries(winnerAmount)
    results.map(result => {
      trimmed.push(result)
    })
    setRandomParticipants(trimmed)
  }, [winnerAmount])

  const handleRandomize = (e) => {
    e.preventDefault()
    let trimmed = []
    const results = getRandomEntries(winnerAmount)
    results.map(result => {
      trimmed.push(result)
    })
    setRandomParticipants(trimmed)
  }

  const fetchWinnerDocs = async (userData) => {
    let arr = []
    await userData.map(user => {
      const id = user.id.replace(/'/g, '"')
      arr.push(id)
    })
    return Auth.fetch(`/verlosung/fetch-docs`, {
      method: 'POST',
      body: JSON.stringify(arr)
    })
      .then(res => { return res })
  }

  const lockWinners = async (e) => {
    e.preventDefault()
    setLoading(true)
    fetchWinnerDocs(randomParticipants).then((data) => {
      Auth.fetch(`/verlosung/bulk-winners`, {
        method: 'POST',
        body: JSON.stringify(data)
      })
      setLoading(false)
      setStatusMessage(`Fertig! ${randomParticipants.length} Gewinner wurden hinzugefügt.`)
      setWinnerAmount(0)
      return setUpdate(true)
    })
  }
  const removeWinners = async (e) => {
    e.preventDefault()
    if (winners && winners.length < 1) {
      return
    }
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    setLoading(true)
    let trimmed = []
    await winners.map(winner => trimmed.push(winner.doc))
    console.log(trimmed)
    await Auth.fetch(`/verlosung/bulk-remove`, {
      method: 'POST',
      body: JSON.stringify(trimmed)
    })
    setLoading(false)
    setStatusMessage(`Fertig! ${trimmed.length} Gewinner wurden zurückgesetzt.`)
    return setUpdate(true)
  }
  const handleHover = (e, hover) => {
    e.preventDefault()
    if (hover && !hoverSpin) {
      setHoverSpin(true)
    } else if (!hover && hoverSpin) {
      setHoverSpin(false)
    } else {
      setHoverSpin(false)
    }
  }

  return (
    <div>
      <h3 className='mb-3'>Übersicht:</h3>
      <div className="row mb-3">
        <div className="col">
          <span className="pb-1">Bestätigte Anmeldungen: {participants && participants.length > 0 ? participants.length : 0}</span><br />
        </div>
      </div>
      {!blockedPage &&
        <div className="row mb-3">
          <div className="col-12">
            <button
              onClick={getDuplicates}
              onMouseEnter={(e) => handleHover(e, true)}
              onMouseLeave={(e) => handleHover(e, false)}
              type="button"
              className={`btn btn-primary`}>Duplikate suchen{' '}
              <FontAwesomeIcon icon={faArrowsRotate} {...hoverSpin && { spin: true }} />
            </button>
          </div>
        </div>
      }
    </div>
  )
}

