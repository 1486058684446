import React, { useReducer, useEffect, useState } from 'react'
import AuthService from './AuthService.jsx'
import { ReactComponent as Fax } from './logo-spinning.svg'

const Auth = AuthService.getInstance()

const Todo = props => {
  const [image, setImage] = useState('')
  const [renderpdf, setrenderpdf] = useState([])
  const [PDF, setPDF] = useState('')

  const fetchPDF = () => {
    try {
      ['fax', 'signature'].forEach(type => {
        if (
          props.doc._attachments && props.doc._attachments['fax.pdf'] !== undefined ||
          type === 'signature'
        ) {
          Auth.fetch(`/attachments/${encodeURIComponent(
            props.doc._id
          )}/${type === `fax` ? `fax.pdf` : `signature`}`, {}, true)
            .then(data => {
              data.error ? console.error(data.message) : data.arrayBuffer().then(buffer => {
                const base64Flag = `${
                  type === `fax` ? `data:application/pdf` : `data:image/png`
                };base64,`
                const bufferFile = Buffer.from(buffer).toString('base64')
                const string = base64Flag + bufferFile
                type === `fax` ? setPDF(string) : setImage(string)
              })
            })
        }
      })
    } catch (e) {
      alert('failed', e)
    } finally {
    }
  }

  return  (
    <div className={`card mb-3 todo ${props.pendingTodo[props.doc._id] || ``}`}>
      <div className="card-header d-flex align-items-center">
        <span className="badge badge-primary mr-3 user-selection-none">{props.index + 1}</span>
        <strong>
          <span className="user-selection-none">ID: </span>
          <a href={`http://127.0.0.1:15984/_utils/#database/sanktionsfrei/${encodeURIComponent(props.doc._id)}`} target="_blank" title={JSON.stringify(props.doc)}>{props.doc._id}</a>
        </strong>
        <span className="user-selection-none mx-2">|</span>
        {props.doc.letterDate && `Termin am ${props.doc.letterDate}`}
        {props.doc.sanctionLetterDate &&
        `Sanktioniert am ${props.doc.sanctionLetterDate}`}
      </div>

      <div className="card-body p-3">
        
        {props.doc.case && props.doc.case.type && (
          <strong
            data-case-type="missedAppointment"
            data-case-reason="none"
            className={`text-capitalize`}
          >
            <h4>{props.doc.case.type} {props.doc.case.reason && ` - Grund: ${props.doc.case.reason}`} {props.doc.missedAppointment}</h4>
          </strong>
        )}
        <div className="d-flex align-items-stretch justify-content-between">
          <div className="d-flex align-items-start w-50">
            <table className="address table table-sm table-striped mb-0">
              <tbody>
              <tr>
                <td className={``}>
                  <strong>Name</strong>:
                </td>
                <td className={``}>
                  {props.doc.firstName} {props.doc.familyName}
                </td>
              </tr>
              <tr>
                <td className={``}>
                  <strong>Address</strong>:

                </td>
                <td className={``}>
                  {props.doc.streetAndNumber} {props.doc.zipCode} {props.doc.city}
                </td>
              </tr>
              {props.doc.email && (
                <tr>
                  <td className={``}>
                    <strong>Email</strong>
                  </td>
                  <td className={``}>
                    <a href={`mailto:${props.doc.email}`}>{props.doc.email}</a>
                  </td>
                </tr>
              )}
              <tr>
                <td className={``}>
                  <strong>BG-Nr</strong>:
                </td>
                <td className={``}>
                  {props.doc.caseId}
                </td>
              </tr>
              <tr>
                <td className={``}>
                  <strong>Jobcenter</strong>:
                </td>
                <td className={``}>
                  {props.doc.jobCenter} (
                  <a
                    target="_blank"
                    href={`https://www.google.de/search?hl=de&q=jobcenter+${encodeURI(props.doc.jobCenter)}`}
                  >
                    JobCenter suchen
                  </a>
                  )
                </td>
              </tr>
              <tr>
                <td className={``}>
                  <strong>Faxnummer</strong>:
                </td>
                <td className={``}>
                  {props.doc.jobCenterFaxNumber} (

                  <a
                    target="_blank"
                    href={`https://www.google.de/search?hl=de&q=${
                      props.doc.jobCenterFaxNumber
                    }`}
                  >
                    Faxnummer suchen
                  </a>
                  )
                </td>
              </tr>
              <tr>
                <td className={``}>
                  <strong>Interfax-ID</strong>:
                </td>
                <td className={``}>
                  {props.doc.faxId}
                </td>
              </tr>
              <tr>
                <td className={``}>
                  <strong>Angelegt am:</strong>
                </td>
                <td className={``}>
                  {props.doc.createdAt}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Brief:</strong>
                </td>
                <td>
                  {props.doc.letterDate}
                </td>
              </tr>
              {props.doc.lawyerCallbackRequest && (
                <tr>
                  <td colSpan={2}>
                    <strong>Anwaltrückruf-Anfrage</strong>
                  </td>
                </tr>
                )}
                {props.doc.lawyerCallbackRequest && (
                <tr>
                  <td className={``}>
                    <strong>Name</strong>
                  </td>
                  <td className={``}>
                     {props.doc.lawyerCallbackRequest.clientName}
                  </td>
                </tr>
                )}
                {props.doc.lawyerCallbackRequest && (
                <tr>
                  <td className={``}>
                    <strong>Rückrufnummer</strong>
                  </td>
                  <td className={``}>
                     {props.doc.lawyerCallbackRequest.callbackNumber}
                  </td>
                </tr>
                )}
                {props.doc.lawyerCallbackRequest && (
                <tr>
                  <td className={``}>
                    <strong>Rückrufzeit</strong>
                  </td>
                  <td className={``}>
                    {props.doc.lawyerCallbackRequest.callbackDatetime}
                  </td>
                </tr>
                )}
                {props.doc.lawyerCallbackRequest && (
                <tr>
                  <td className={``}>
                    <strong>Grund</strong>
                  </td>
                  <td className={``}>
                     {props.doc.lawyerCallbackRequest.reason}
                  </td>
                </tr>
              )}
              </tbody>
            </table>
            {/*<img className="signature" src={image} alt=""/> */}
          </div>
          <div className={`w-50`}>
            {renderpdf.includes(props.doc._id) ? !PDF ? (
              <strong>Kein Fax vorhanden</strong>
            ) : (
              <embed
                src={PDF}
                width="100%"
                height="100%"
                type="application/pdf"
              />
            ) : (
              <div className="text-center">
                <button className="btn btn-primary" onClick={(e) => {
                  fetchPDF()
                  setrenderpdf([...renderpdf, props.doc._id])
                }}>
                  Show PDF
                </button>
              </div>
            )}
          </div>
        </div>
        {props.doc.errors && (
          <div className="alert alert-danger mt-3 mb-0" role="alert">
            Error: {JSON.stringify(props.doc.errors)}
          </div>
        )}
      </div>
      {props.doc.status !== 'completed' && (
        <div className="card-footer">
          {/*{props.doc &&*/}
          {/*props.doc._attachments &&*/}
          {/*props.doc._attachments['fax.pdf'] &&*/}
          {/*!props.doc.profile && (*/}
          {/*  <button*/}
          {/*    type="button"*/}
          {/*    className="btn btn-primary mr-3"*/}
          {/*    data-action="preview-pdf"*/}
          {/*  >*/}
          {/*    Vorschau*/}
          {/*  </button>*/}
          {/*)}*/}
          <div>
            {props.doc.status !== 'rejected' && (
              <button
                type="button"
                className="btn btn-danger mr-3"
                onClick={(e) => props.onTodoSubmit(e, props.doc, true)}
              >
                Ablehnen
              </button>
            )}
            <button
              type="button"
              className="btn btn-success mr-3"
              onClick={(e) => props.onTodoSubmit(e, props.doc)}
            >
              {props.doc.status !== 'rejected' ? 'Abschicken' : 'Nochmal versuchen'}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
export default Todo
