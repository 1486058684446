import React, { useState, useEffect, useContext } from 'react'
import './App.css'
import Todo from './Todo'

import withAuth from './withAuth'
import { AppContext } from './AppProvider.jsx'
import AuthService from './AuthService.jsx'
import Pagination from './Pagination'
import WithLoading from './WithLoading'

const Auth = AuthService.getInstance()

const Todos = props => {
  const { state, dispatch } = useContext(AppContext)
  const [pendingTodo, setPendingTodo] = useState({})
  const [loading, setloading] = useState(true)
  const [overview, setoverview] = useState([])
  const [filter, setfilter] = useState('todo')
  useEffect(() => {
    Auth.fetch(`/${filter}`).then(data => {
      setoverview(data.counts)
      dispatch({ type: 'FETCH_TODOS', data: data.rows
        // .splice(0, 10)
        .sort((a,b) => new Date(b.doc.createdAt) - new Date(a.doc.createdAt))})
      setloading(false)
    })
  }, [filter])
  const queue = [
    'todo',
    'errors',
    'sent',
    'rejected'
  ]
  const onTodoSubmit = (event, todo, deleteItem = false) => {
    event.preventDefault()
    setPendingTodo({ [todo._id]: `loading` })
    const body = JSON.stringify({
      id: todo._id
    })
    Auth.fetch(`/api/${deleteItem ? `rejected` : `approved`}`, {
      method: 'POST',
      body: body
    }).then(data => {
      setPendingTodo({ [todo._id]: `deleting` })
      setTimeout(() => {
        setPendingTodo({})
        dispatch({ type: 'REMOVE_TODO', data: todo._id })
      }, 1000)
      dispatch({
        type: 'TOGGLE_TOAST',
        data: {
          open: true,
          type: data.action === `rejected` ? `danger` : `success`,
          message: data.action === `rejected` ? `Fax successfully rejected` : `Fax successfully approved`,
        },
      })
    })
  }
  var maps = {
    todo: 'ready_for_review',
    errors: 'error',
    sent: 'completed',
    rejected: 'rejected',
    hartzbreaker: 'hartzbreaker'
  }
  return (
    <div className="container-fluid">
      <div className="btn-group mb-3" role="group" aria-label="Basic example">
        {queue.map((queueitem, index) => (
          <button type={`button`} key={index} onClick={() => {
            setloading(true)
            setfilter(queueitem)
          }}
            className={`btn btn-outline-primary ${queueitem === filter ? `active` : ``}`}>{queueitem}
            <span className="badge badge-secondary ml-1">{overview[maps[queueitem]]}</span>
            </button>
        ))}
      </div>

      <WithLoading loading={loading} waitfor={state.todos}>
        <Pagination items={state.todos}/>
        {state.todos && state.todos.slice(state.offset, state.offset + state.limit).map((row, index) => row.doc ? (
          <Todo
            pendingTodo={pendingTodo}
            onTodoSubmit={onTodoSubmit}
            index={index}
            key={row.doc._id}
            doc={row.doc}
          />
        ) : `Missing`)}
        <Pagination items={state.todos}/>
      </WithLoading>
    </div>
  )
}
export default withAuth(Todos)
