import React, { useState, useEffect, useContext } from 'react'
import AuthService from './AuthService.jsx'
import ActionButton from './components/ActionButton.jsx'
import { AppContext } from './AppProvider.jsx'
import { downloadWinners, downloadUploads } from './helper.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


const Auth = AuthService.getInstance()

export default function WinnerControls({ setUpdate, setLoading, setStatusMessage, emailReady, emailReminder, uncheckedData, readyForExport, filterNewData, setFilterNewData, exported, feedbackMailSent, expired }, ...props) {
  const { state, dispatch } = useContext(AppContext)
  const winners = state.winners
  const [prizeAmount, setPrizeAmount] = useState(150)
  const [bookingText, setBookingText] = useState("")
  const [exportEnabled, setExportEnabled] = useState(false)

  useEffect(() => {
    if (readyForExport.length > 0) {
      if (bookingText.length > 2 && prizeAmount > 0) {
        setExportEnabled(true)
      } else {
        setExportEnabled(false)
      }
    } else {
      setExportEnabled(false)
    }
  }, [bookingText, prizeAmount])

  const sendMails = async (e) => {
    e.preventDefault()
    setLoading(true)

    const sendBulkMails = async () => {
      return await Auth.fetch('/verlosung/bulk-emails', { method: 'POST' })
    }
    await sendBulkMails()
    return setUpdate(true)
  }

  const sendReminders = async (e) => {
    e.preventDefault()
    setLoading(true)

    const sendBulkReminders = async () => {
      return await Auth.fetch('/verlosung/bulk-reminders', { method: 'POST' })
    }
    await sendBulkReminders()
    return setUpdate(true)
  }

  const sendFeedbackMails = async (e) => {
    e.preventDefault()
    setLoading(true)

    const sendBulkFeedback = async () => {
      return await Auth.fetch('/verlosung/bulk-feedback', { method: 'POST' })
    }
    await sendBulkFeedback()
    return setUpdate(true)
  }

  const handleAmtChange = (e) => {
    setPrizeAmount(e.target.value)
  }

  const handleBookgTxtChange = (e) => {
    setBookingText(e.target.value)
  }

  const handleFilter = (e) => {
    e.preventDefault()
    setFilterNewData(!filterNewData)
  }

  const handleExport = async (e) => {
      setStatusMessage(null)
      setLoading(true)
    setStatusMessage('Export gestartet.')
      try {
        await downloadWinners(e).then(data => data && setStatusMessage(`${data.statusText} Überweisungen wurden exportiert.`))
      } catch (err) {
        setStatusMessage(String(err))
        setLoading(false)
      }
    return setUpdate(true)
  }
  const handleBulkDownload = async (e) => {
    setStatusMessage(null)
    setLoading(true)
    setStatusMessage('Bitte warten, die Dateien werden zum Download vorbereitet.')
    try {
      await downloadUploads(e).then(data => data && setStatusMessage(`${data.statusText} Leistungsnachweise werden heruntergeladen.`))
    } catch (err) {
      setStatusMessage(String(err))
      setLoading(false)
    }
    setLoading(false)
  }
  const blockWinners = async (e) => {
    e.preventDefault()
    if (expired && expired.length < 1) {
      return
    }
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    setLoading(true)
    let trimmed = []
    await expired.map(winner => trimmed.push(winner.doc))
    console.log(trimmed)
    await Auth.fetch(`/verlosung/bulk-block`, {
      method: 'POST',
      body: JSON.stringify(trimmed)
    })
    setLoading(false)
    setStatusMessage(`Fertig! ${trimmed.length} inaktive Gewinner wurden geblockt.`)
    return setUpdate(true)
  }

  return (
    <div>
      <div className="row mb-3">
        <div className="col-6">
          <div className="mb-0 pb-0">Gewinner gesamt: {winners.length > 0 ? winners.length : 0}</div>
        </div>
        <div className="col-6">
          <div className="mb-0 pb-0">Neue Nachweise: {uncheckedData.length > 0 ? uncheckedData.length : 0}</div>
        </div>
        <div className="col-6">
          <div className="mb-0 pb-0">Nächster Export: {readyForExport.length > 0 ? readyForExport.length : 0}</div>
        </div>
        <div className="col-6">
          <div className="mb-0 pb-0">Feedbackmails: {exported.length > 0 ? exported.length : 0}</div>
        </div>
        <div className="col-6">
          <div className="mb-0 pb-0">Abgeschlossen: {feedbackMailSent.length > 0 ? feedbackMailSent.length : 0}</div>
        </div>
      </div>
      {expired.length > 0 &&
        <div className="row mb-3">
          <div className="col-6">
            <div className="mb-0 pb-0 text-danger">Frist abgelaufen: {expired.length > 0 ? expired.length : 0}</div>
          </div>
        </div>}
      <div className="my-4">
        {expired.length > 0 &&
          <div className="row">
            <div className="col">
              <div className="alert alert-danger text-center" role="alert">
                <p>{expired.length} Gewinner {expired.length === 1 ? "hat" : "haben"} seit mehr als 7 Tagen nicht reagiert.</p>
                <button onClick={blockWinners} type="button" className="btn btn-danger">Gewinner sperren</button>
              </div>
            </div>
          </div>
        }
        <div className="row mb-3">
          <div className="col">
            <h4 className={`mb-2 ${emailReady.length === 0 && 'text-success'}`}>Gewinner kontaktieren {emailReady.length === 0 && <FontAwesomeIcon icon={faCheck} />}</h4>
            {emailReady.length > 0 &&
              <div className="alert alert-warning text-center" role="alert">
                <p>{emailReady.length} Gewinner {emailReady.length === 1 ? "kann" : "können"} kontaktiert werden.</p>
                <button onClick={sendMails} type="button" className="btn btn-success">Gewinner kontaktieren</button>
              </div>}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <h4 className={`mb-2 ${emailReminder.length === 0 && 'text-success'}`}>Erinnerungsmails verschicken {emailReminder.length === 0 && <FontAwesomeIcon icon={faCheck} />}</h4>
            {emailReminder.length > 0 &&
              <div className="alert alert-warning text-center" role="alert">
                <p>{emailReminder.length} Gewinner {emailReminder.length === 1 ? "kann" : "können"} erinnert werden.</p>
                <button onClick={sendReminders} type="button" className="btn btn-success">Gewinner erinnern</button>
              </div>}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <h4 className={`mb-2 ${uncheckedData.length === 0 && 'text-success'}`}>Leistungsnachweise prüfen {uncheckedData.length === 0 && <FontAwesomeIcon icon={faCheck} />}</h4>
            {uncheckedData.length > 0 &&
              <div className="alert alert-warning text-center mb-4" role="alert">
                <p>{uncheckedData.length} {uncheckedData.length === 1 ? "neuer Leistungsnachweis." : "neue Leistungsnachweise."}</p>
                <button onClick={handleFilter} type="button" className={`btn ${filterNewData ? "btn-warning" : "btn-success"} mb-3`}>{filterNewData ? "Zurück" : "Anzeigen"} </button>
              </div>}
          </div>
        </div>
        <h4 className={`mb-2 ${readyForExport.length === 0 && 'text-success'}`}>Sammelüberweisung exportieren {readyForExport.length === 0 && <FontAwesomeIcon icon={faCheck} />}</h4> 
        {readyForExport.length > 0 && <div className="alert alert-warning text-center" role="alert">
          {readyForExport.length} Überweisung{readyForExport.length === 1 ? "" : "en"} bereit.
        </div>}
      </div>
      <div className="bg-light mb-4">
        <div className="row">
          <div className="col-12">
            <div className="p-3">
              <h5>1. Höhe des Gewinns</h5>
              <span>Jeder Gewinner erhält</span>
              <input
                onChange={handleAmtChange}
                id="prizeAmount"
                className="ml-3 text-center"
                type="number"
                name="prizeAmount"
                placeholder={150}
                min={0}
                value={prizeAmount}>
              </input>
              <span className='ml-3'>Euro.</span>
            </div>
            <div className="p-3">
              <h5>2. Verwendungszweck</h5>
              <input
                onChange={handleBookgTxtChange}
                className="ml-2 pl-2 w-50"
                type="text"
                placeholder='Mehrbedarf Kampagne'
                value={bookingText} />
              <span> - Auszahlung nach § 11a (4) SGB II</span>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <div className="p-3 pl-4">
              <button
                onClick={handleExport}
                type="button"
                data-type={`/generate/winners?amt=${prizeAmount}&raffle=${encodeURIComponent(bookingText)}`}
                className={`btn mb-3 ${!exportEnabled ? 'btn-secondary' : 'btn-success'}`}
                disabled={!exportEnabled ? true : false}>Exportieren</button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <h4 className={`mb-2 ${exported.length === 0 && 'text-success'}`}>Feedbackmails verschicken {exported.length === 0 && <FontAwesomeIcon icon={faCheck} />}</h4>
          {exported.length > 0 &&
            <div className="alert alert-warning text-center" role="alert">
              <p>{exported.length} Gewinner {exported.length === 1 ? "kann" : "können"} eine Feedbackmail erhalten.</p>
              <button onClick={sendFeedbackMails} type="button" className="btn btn-success">Feedbackmail senden</button>
            </div>}
        </div>
      </div>
      {/*       <div className="row my-3">
        <div className="col d-flex align-items-center">
          <h4 className='mb-0'>Alle Uploads herunterladen: </h4>

          <ActionButton
            type="download"
            onClick={(e) => handleBulkDownload(e)}
            title="Leistungsbescheid herunterladen"
            className="mx-4" />

        </div>
      </div> */}
    </div>
  )
}

