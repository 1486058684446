import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import withAuth from './withAuth.js'
import { AppContext } from './AppProvider.jsx'
import AuthService from './AuthService.jsx'
import WithLoading from './WithLoading.jsx'
import Pagination from './Pagination.jsx'
import ActionButton from './components/ActionButton.jsx'
import DuplicateFinder from './DuplicateFinder.jsx'


const Auth = AuthService.getInstance()
export default withAuth(function RaffleDuplicates(props) {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const [scanComplete, setScanComplete] = useState(false)
  const [duplicates, setDuplicates] = useState(null)
  const [statusMessage, setStatusMessage] = useState()
  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }
  useEffect(() => {
    if (update) {
      setLoading(true)
      try {
        Auth.fetch(`/verlosung/teilnehmer`)
          .then(data => {
            dispatch({
              type: 'FETCH_RAFFLEPARTICIPANTS', data: data.rows
                .sort((a, b) => new Date(b.value.birthdate) - new Date(a.value.birthdate))
            })
            setLoading(false)
            setUpdate(false)
          })
          .catch(error => console.error(error))
      } catch (error) {
        console.error(error)
        setLoading(false)
        setUpdate(false)
      }
      return () => {
        setLoading(false);
        setUpdate(false)
      };
    }
  }, [update, dispatch])

  useEffect(() => {
    setUpdate(true)
  }, [])

  const removeWinner = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/remove/${id}`, { method: 'POST' })
    setStatusMessage(`Gewinner zurückgesetzt.`)
    return setUpdate(true)
  }

  const blockParticipant = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/block/${id}`, { method: 'POST' })
    setStatusMessage(`Teilnehmer blockiert.`)
    return setUpdate(true)
  }

  const unblockParticipant = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/unblock/${id}`, { method: 'POST' })
    setStatusMessage(`Blockierung aufgehoben.`)
    return setUpdate(true)
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 order-md-2">
          <div className="row justify-content-center fixed-height-100">
            {statusMessage &&
              <div className="alert alert-info text-center" role="alert">
                {statusMessage}
              </div>
            }
          </div>
          <div>
            <DuplicateFinder
              setUpdate={setUpdate}
              setLoading={setLoading}
              setStatusMessage={setStatusMessage}
              setScanComplete={setScanComplete}
              setDuplicates={setDuplicates}
              blockedPage={false} />
            <h3 className='mb-3'>Potentielle Duplikate: {scanComplete === true ? duplicates.length : 0}</h3>
            <p>Die Duplikate werden nach Geburtsdatum sortiert aufgelistet</p>
          </div>
        </div>
        <div className="col-md-9 order-md-1">
          <WithLoading loading={loading} waitfor={duplicates}>

            <table className="table table-sm raffle table-responsive">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>E-Mail</th>
                  <th>Angemeldet seit</th>
                  <th>Status</th>
                  <th>Geburtsdatum</th>
                </tr>
              </thead>
              <tbody id="participant-table">
                {scanComplete === true
                  && duplicates.map(participant => (
                    <React.Fragment key={participant.id}>
                      <tr className={participant && participant.value.status === "winner" ? "bg-info winner-all" :
                        participant.value.status === "blocked" ? "bg-warning blocked" : "confirmed"}>
                        <td className='action'>
                          <ActionButton
                            type={`${participant.value.status === "winner" ? "remove" :
                              participant.value.status === "blocked" ? "restore" :
                                "block"}`}
                            onClick={(e) =>
                              participant.value.status === "winner" ? removeWinner(e, participant.id) :
                                participant.value.status === "blocked" ? unblockParticipant(e, participant.id) :
                                  blockParticipant(e, participant.id, participant.key)}
                            title={`${participant.value.status === "winner" ? "Gewinner entfernen" :
                              participant.value.status === "blocked" ? "Blockierung aufheben." :
                                "Teilnehmer blockieren"}`} />
                        </td>
                        <td className='name'>{participant &&
                          <a href={`/verlosung/teilnehmer/${participant.id}`}>
                            {participant.value.firstname} {participant.value.lastname}
                          </a>}
                        </td>
                        <td className='email'>
                          {participant && <a href={`mailto:${participant.value.email}`} >{participant.value.email}</a>}
                        </td>
                        <td className='date'>{participant && new Intl.DateTimeFormat("de-DE", dateOptions).format(Date.parse(participant.value.createdAt))}</td>
                        <td className='status'>{participant && participant.value.status}</td>
                        <td className='birthdate'>{participant && participant.value.birthdate}</td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </WithLoading>
        </div>
      </div>
    </div>
  )
})
