import React, { useEffect, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faDownload, faTrash, faXmark, faUser, faUserSlash, faTrophy } from '@fortawesome/free-solid-svg-icons'

export default function ActionButton({ onClick, title, type, className = ' ', disabled = false, dataConfirm }, ...props) {
    const [hover, setHover] = useState()

    return (
        <>
            {type === "block" &&
                <button
                    onClick={onClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={`btn ${hover ? 'btn-outline-danger' : 'btn-outline-dark'}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={title}
                    disabled={disabled}
                    data-confirm={dataConfirm}
                >
                    <FontAwesomeIcon icon={hover ? faUserSlash : faUser} />
                </button>
            }
            {type === "remove" &&
                <button
                    onClick={onClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={className + ' ' + `btn ${hover ? 'btn-danger' : 'btn-info'}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={title}
                    disabled={disabled}
                    data-confirm={dataConfirm}
                >
                    <FontAwesomeIcon icon={hover ? faXmark : faTrophy} />
                </button>
            }
            {type === "restore" &&
                <button
                    onClick={onClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={className + ' ' + `btn ${hover ? 'btn-light' : 'btn-danger'}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={title}
                    disabled={disabled}
                    data-confirm={dataConfirm}
                >
                    <FontAwesomeIcon icon={hover ? faUser : faUserSlash} />
                </button>
            }
            {type === "delete" &&
                <button
                    onClick={onClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={className + ' ' + `btn ${hover ? 'btn-danger' : 'btn-outline-danger'}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={title}
                    disabled={disabled}
                    data-confirm={dataConfirm}
                >
                    <FontAwesomeIcon icon={hover ? faTrash : faTrash} />
                </button>
            }
            {type === "approve" &&
                <button
                    onClick={onClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={className + ' ' + `btn ${hover ? 'btn-success' : 'btn-outline-success'} 
                    ${disabled ? 'd-none' : 'd-block'}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={title}
                    disabled={disabled}
                    data-confirm={dataConfirm}
                >
                    <FontAwesomeIcon icon={hover ? faCheck : faCheck} />
                </button>
            }
            {type === "reject" &&
                <button
                    onClick={onClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={className + ' ' + `btn ${hover ? 'btn-danger' : 'btn-outline-danger'} 
                    ${disabled ? 'd-none' : 'd-block'}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={title}
                    disabled={disabled}
                    data-confirm={dataConfirm}
                >
                    <FontAwesomeIcon icon={hover ? faXmark : faXmark} />
                </button>
            }
            {type === "download" &&
                <button
                    onClick={onClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={className + ' ' + `btn ${hover ? 'btn-info' : 'btn-outline-dark'}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={title}
                    disabled={disabled}
                    data-confirm={dataConfirm}
                >
                    <FontAwesomeIcon icon={hover ? faDownload : faDownload} />
                </button>
            }
        </>
    )
}