import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import withAuth from './withAuth.js'
import { AppContext } from './AppProvider.jsx'
import AuthService from './AuthService.jsx'
import WithLoading from './WithLoading.jsx'
import Pagination from './Pagination.jsx'
import RandomRaffleSelector from './RandomRaffleSelector.jsx'
import ActionButton from './components/ActionButton.jsx'


const Auth = AuthService.getInstance()
export default withAuth(function RaffleBlocked(props) {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const [statusMessage, setStatusMessage] = useState()
  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }
  useEffect(() => {
    if (update) {
      setLoading(true)
      try {
        Auth.fetch(`/verlosung/blocked`)
          .then(data => {
            dispatch({ type: 'FETCH_BLOCKEDPARTICIPANTS', data: data.rows })
            setLoading(false)
            setUpdate(false)
          })
          .catch(error => console.error(error))
      } catch (error) {
        console.error(error)
        setLoading(false)
        setUpdate(false)
      }
    }
  }, [update, dispatch])

  useEffect(() => {
    setUpdate(true)
  }, [])


  const unblockParticipant = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/unblock/${id}`, { method: 'POST' })
    setStatusMessage(`Blockierung aufgehoben.`)
    return setUpdate(true)
  }

  let blocked = state.blocked

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 order-md-2">
          <div className="row justify-content-center fixed-height-100">
            {statusMessage &&
              <div className="alert alert-info text-center" role="alert">
                {statusMessage}
              </div>
            }
          </div>
          <RandomRaffleSelector
            setUpdate={setUpdate}
            setLoading={setLoading}
            setStatusMessage={setStatusMessage}
            blockedPage={true} />
        </div>
        <div className="col-md-9 order-md-1">
          <WithLoading loading={loading} waitfor={blocked}>
            <table className="table table-sm raffle table-responsive">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>E-Mail</th>
                  <th>Angemeldet seit</th>
                  <th>Status</th>
                  <th>Geburtsdatum</th>
                </tr>
              </thead>
              <tbody id="participant-table">
                {state.blocked && state.blocked.length > 0
                  && state.blocked.map(participant => (
                    <React.Fragment key={participant.doc._id}>
                      <tr className={participant && participant.doc.status === "blocked" ? "blocked bg-warning" : ""}>
                        <td className='action'>
                          <ActionButton
                            type="restore"
                            onClick={(e) => unblockParticipant(e, participant.doc._id)}
                            title="Blockierung aufheben."
                          />
                        </td>
                        <td className='name'>{participant && <a href={`/verlosung/teilnehmer/${participant.doc._id}`}>{participant.doc.firstname} {participant.doc.lastname}</a>}</td>
                        <td className='email'><a href={`mailto:${participant.doc.email}`} >{participant.doc.email}</a></td>
                        <td className='date'>{participant && participant.doc.createdAt && new Intl.DateTimeFormat("de-DE", dateOptions).format(Date.parse(participant.doc.createdAt))}</td>
                        <td className='status'>{participant && participant.doc.status}</td>
                        {/* <td className='bg-number'>{participant && participant.doc.bgNumber}</td> */}
                        <td className='birthdate'>{participant && participant.doc.birthdate}</td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </WithLoading>
        </div>
      </div>
    </div>
  )
})
