import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from './AppProvider.jsx'
import AuthService from './AuthService.jsx'

const Auth = AuthService.getInstance()
const Login =  props => {
  const { dispatch } = useContext(AppContext)
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (Auth.loggedIn()) {
      props.history.replace("/");
    }
  }, [props.history]);
  const signIn = e => {
    setLoading(true)
    e.preventDefault()
    Auth.login(user.username.toLowerCase(), user.password)
      .then(data => {
        setLoading(false)
        dispatch({ type: 'LOGIN', data: data.user })
        props.history.replace('/')
        dispatch({
          type: 'TOGGLE_TOAST',
          data: {
            open: true,
            type: `success`,
            message: data.message
          },
        })
      })
      .catch(e => {
        console.error(e)
        setLoading(false)
        dispatch({
          type: 'TOGGLE_TOAST',
          data: {
            open: true,
            type: `danger`,
            message: JSON.stringify(e),
          },
        })
      })
  }
  const handleChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }
  return (
    <div className="h-100">
      <div className="App">
        <form onSubmit={event => signIn(event)} className="form-signin">
          <h1 className="h3 my-5 font-weight-normal">Faxbau login</h1>
          <label htmlFor="username" className="sr-only">
            Name
          </label>
          <input
            onChange={handleChange}
            type="text"
            id="username"
            name="username"
            className="form-control"
            placeholder="Name"
            required
            autoFocus
          />
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            onChange={handleChange}
            type="password"
            id="password"
            name="password"
            className="form-control"
            placeholder="Password"
            required
          />
          <button
            disabled={loading}
            className={`btn-outline-primary mb-3 btn btn-lg  btn-block`}
          >
            {!loading ? 'Signin' : 'Loading'}
          </button>

          <p className="mt-5 mb-3 text-muted">&copy; 2017-2018</p>
        </form>
      </div>
    </div>
  )
};
export default Login;
